<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>{{ title }} <span>Move Asset</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <ul class="move_step_bar">
                        <li v-for="(item, i) in folderBreadcrumb" :key="i" @click="handleBreadcrumb(item, i)">{{ i != 0 ? `-> ${item.title}` : item.title}}</li>
                    </ul>
                    <label for="return" class="switch_option capsule_btn">
                        <h5>Return To Main Folder?</h5>
                        <input type="checkbox" id="return" value="0" v-model="params.folder_id" @click="handelReturnMainFolder()" hidden>
                        <div><span></span></div>
                    </label>
                    <ul class="folder_list">
                        <li v-for="(folder, f) in folders" :key="f">
                            <div class="card_item">
                                <img src="@/assets/images/folder2.svg" @click="selectFolder(folder)">
                                <p>{{ folder.title }}</p>
                                <input type="radio" :id="`main-${f}`" :value="folder.id" v-model="params.folder_id">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button :disabled="loader" type="button" class="btn save_btn" @click="handleMoveAsset()"><i class="fa fa-spinner fa-spin" v-if="loader"></i>  {{ loader ? 'Moveing' : 'Move' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Move Asset',

        data () {
            return {
                params: {
                    assets_id: '',
                    folder_id: 0,
                    asset_type: '',
                },
                folderParams: {
                    archived: 0,
                    parent_id: 0,
                    asset_type: ''
                },
                folderBreadcrumb: [],
            }
        },

        props: {
            modelValue: Boolean,
            selectedAsset: Object,
            title: String,
            assetType: String,
            refreshAsset: Function,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.resetForm();

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            loader: state => state.folderModule.addAssetLoader,
            folders: state => state.folderModule.folders,
        }),

        methods: {
            ...mapActions({
                getFolder: 'folderModule/getFolder',
                moveAsset: 'folderModule/addAssets'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.params.asset_type       = vm.assetType;
                vm.params.assets_id        = [vm.selectedAsset.id];
                vm.params.folder_id        = 0;

                vm.folderParams.archived   = 0;
                vm.folderParams.parent_id  = 0;
                vm.folderParams.asset_type = vm.assetType;

                vm.folderBreadcrumb        = [];

                vm.getFolder(vm.folderParams);
            },

            selectFolder (folder) {
                const vm = this;

                if (folder.contenteditable == false && folder.has_sub_folders) {
                    vm.folderParams.parent_id = folder.id;

                    vm.folderBreadcrumb.push(folder);

                    vm.getFolder(vm.folderParams);
                } else {
                    vm.params.folder_id = folder.id;
                }
            },

            handleMoveAsset () {
                const vm = this;

                if (vm.params.folder_id) {
                    vm.moveAsset(vm.params).then((result) => {
                        vm.folderParams.parent_id = vm.selectedAsset.parent_id;

                        vm.$parent.assetFolderWatcher += 1;
                        vm.refreshAsset();
                        vm.closeModal();
                    });
                } else {
                    const option = Helper.swalWarningOptions('Alert!', 'Please select at least one folder', 'Okay')

                    Swal.fire(option);
                }
            },

            handleBreadcrumb (folder, index) {
                const vm   = this;
                let length = vm.folderBreadcrumb.length;

                vm.folderBreadcrumb.splice(index + 1, length);
                vm.folderParams.parent_id = folder.id;

                vm.getFolder(vm.folderParams);
            },

            handelReturnMainFolder () {
                const vm   = this;

                vm.folderBreadcrumb       = [];
                vm.folderParams.parent_id = 0;

                vm.getFolder(vm.folderParams);
            }
        }
    }
</script>

<style>
    .folder_list {
        display: flex;
        margin-top: 20px;
        flex-wrap: wrap;
    }

    .folder_list .card_item {
        height: 150px;
        /* width: 35%; */
        margin: 5px;
    }

    .folder_list li {
        width: 33.333%;
        margin-bottom: 10px;
    }

    .move_step_bar {
        display: flex;
    }

    .move_step_bar li {
        margin-right: 10px;
    }
</style>
